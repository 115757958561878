<template>
  <skeleton-loader
    type="square"
    :width="width"
    :height="height"
    :rounded="true"
    animation="fade"
  />
</template>
<script>
export default {
  name: "TableSkeletonLoader",
  props: {
    width: {
      default: 100,
    },
    height: {
      default: 15,
    },
  },
};
</script>